<script>
import AsyncExportButton from "@/components/async-export-button.vue";
import DropdownCaptacao from "@/components/dropdown-captacao.vue";
import Modal from "@/components/g-modal.vue";
import Pagination from "@/components/pagination.vue";
import Table from "@/components/table-v.vue";
import generateRandomFn from "@/helpers/seededRandom.js";
import toMonth from "@/helpers/toMonth";
import moment from "moment";
import { mapGetters } from "vuex";

const PERCENTUAL = "Percentual dedicado a P&D";
const BENEFICIO = 4; // Captação

// TODO apagar este mock pós integração
/**
 * @function
 * @param {[string, string]} intervaloCompetencia
 */
const generateResponseMock = intervaloCompetencia => {
  const rand = generateRandomFn(intervaloCompetencia.join());
  const [compIni, compFim] = intervaloCompetencia.map((value) => moment(value, 'YYYY-MM'));
  const monthsLength = compFim.diff(compIni, 'months') + 1;
  const rubricas = [
    [
      "Total de vencimentos",
      ["#F774 — Salário/Horas Trabalhados", "#F779 — Adicional Insalubridade"]
    ],
    ["Total Custo Funcionário", ["INSS Folha", "FGTS Folha"]],
    ["Valor dedicado a P&D", ["Percentual dedicado a P&D"]]
  ];
  const totalPages = 10;
  const data = Array.from({ length: totalPages }, (_, cola) => {
    return Array.from({ length: monthsLength }, (_, comp) => ({
      competencia: moment(compIni).add(comp, 'month').format('YYYY-MM')
    })).reduce((acc, { competencia }) => {
      const datas = rubricas.reduce((acc, [somatorio, rubs]) => {
        const parsed = rubs.map(rubrica => ({
          competencia,
          colaborador: `Colaborador ${cola + 1}`,
          dedicacao: Math.floor(rand() * 100) + 1,
          rubrica,
          somatorio,
          valor: Math.floor(rand() * (rubrica === PERCENTUAL ? 100 : 1000)) + 1
        }));

        return [...acc, ...parsed];
      }, []);

      return [...acc, ...datas];
    }, []);
  });

  return { data, totalPages };
};

export default {
  components: {
    AsyncExportButton,
    DropdownCaptacao,
    Pagination,
    Modal,
    Table
  },
  data() {
    const intervaloCompetencia = this.$store.getters.intervaloCompetencia;
    return {
      colaborador: "",
      colaboradores: [],
      colaboradoresSelecionados: [],
      competencias: [],
      competenciaRecalculo: [...intervaloCompetencia],
      dateMenu: false,
      funcionarioId: null,
      intervaloCompetencia,
      opts: {},
      page: 1,
      recalcularTodas: false,
      rows: [],
      searchInputValue: "",
      totalPages: 1
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    actionBarButtons() {
      return [
        {
          text: "Exportação Assíncrona",
          icon: "mdi-cloud-refresh-outline",
          action: () => {
            this.$refs.asyncExportButton.show = true;
          }
        },
        {
          text: "Recalcular",
          icon: "mdi-cog-sync",
          action: () => {
            this.$refs.recalcModal.open();
          }
        }
      ];
    },
    beneficio() {
      return BENEFICIO;
    },
    cols() {
      const months = this.competencias.map(comp => ({
        key: comp,
        name: toMonth(comp),
        type: this.$fieldTypes.TEXT,
        sortable: false,
        align: 1
      }));

      return [
        {
          key: "rubrica",
          name: this.colaborador ?? "",
          type: this.$fieldTypes.TEXT,
          sortable: false
        },
        ...months
      ];
    },
    projetoId() {
      return this.$route.params.id;
    },
    showPagination() {
      return this.totalPages > 1;
    }
  },
  created() {
    this.getColaboradores();
    this.getPage();
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    async doRecalc(close) {
      try {
        const { save } = this.apiResource(
          `/v1/rh/clientes/${this.clientId}/dispendio/recalc`
        );
        const [competenciaIni, competenciaFim] = this.competenciaRecalculo;
        await save({
          colaboradores: this.colaboradoresSelecionados,
          competenciaIni,
          competenciaFim,
          trabalho: BENEFICIO,
          todasFolhas: this.recalcularTodas
        });
        this.$notify({
          group: "geral",
          duration: 7000,
          type: "sucess",
          title: "Solicitado recalculo com sucesso",
          text: "As competências serão recalculadas em breve..."
        });
        this.page = 1;
        this.getPage();
        close();
      } catch (error) {
        this.notify(error);
      }
    },
    async getColaboradores() {
      try {
        const { get } = this.apiResource(`/v1/rh/${this.clientId}/selecao`);
        const response = await get();
        this.colaboradores = response.colaboradores
          .sort(({ nome: a }, { nome: b }) => a.localeCompare(b))
          .map(col => ({
            ...col,
            nome: `${col.matricula || `Sem matrícula`} — ${col.nome}`
          }));
      } catch (error) {
        this.notify(error);
        this.colaboradores = [];
      }
    },
    async getPage() {
      try {
        const { toCurrency } = this.$options.filters;
        // TODO aqui vai vir a integração com a API
        const response = generateResponseMock(this.intervaloCompetencia);
        this.totalPages = response.totalPages;
        const datas = response.data[this.page - 1];
        this.colaborador = datas.length > 0 ? datas[0].colaborador : "";
        this.competencias = datas
          .reduce(
            (acc, { competencia }) =>
              acc.includes(competencia) ? acc : [...acc, competencia],
            []
          )
          .sort();

        this.rows = datas
          .reduce(
            (groups, { competencia, rubrica, somatorio, valor, ...rest }) => {
              let group = groups.find(({ name }) => name === somatorio);

              if (!group) {
                group = {
                  name: somatorio,
                  data: [],
                  total: { rubrica: somatorio }
                };
                groups.push(group);
              }

              let found = group.data.find(row => row.rubrica === rubrica);

              if (!found) {
                found = { rubrica, [competencia]: 0, ...rest };
                group.data.push(found);
              }

              if (!group.total[competencia]) {
                group.total[competencia] = 0;
              }

              // TODO o que eu faço com a dedicação? É igual? Acumula?
              found[competencia] = valor;
              group.total[competencia] += valor;

              return groups;
            },
            []
          )
          .reduce(
            (acc, { data, total }) => [
              ...acc,
              ...data.sort(({ rubrica: a }, { rubrica: b }) => a - b),
              total
            ],
            []
          )
          .map(row => {
            this.competencias.forEach(competencia => {
              row[competencia] =
                row.rubrica === PERCENTUAL
                  ? `${row[competencia]}%`
                  : toCurrency(row[competencia]);
            });

            return row;
          });
      } catch (error) {
        this.notify(error);
        this.colaborador = "";
        this.competencias = [];
        this.rows = [];
        this.totalPages = 1;
      }
    }
  },
  watch: {
    intervaloCompetencia(next, prev) {
      if (Array.isArray(next) && next.length === 2 && next.some((month) => !Array.isArray(prev) || !prev.includes(month))) {
        this.getPage();
      }
    }
  }
};
</script>

<template>
  <Table
    ref="table"
    :actionBarButtons="actionBarButtons"
    class="masterDetail"
    :cols="cols"
    disableHover
    disablePagination
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasFilter="false"
    :hasNewButton="false"
    :hasRefresh="false"
    height="auto"
    :monthlyFilter.sync="intervaloCompetencia"
    :opts="opts"
    :rows="rows"
  >
    <v-autocomplete
      v-model="funcionarioId"
      clearable
      dense
      hide-details
      :items="colaboradores"
      item-value="id"
      item-text="nome"
      prefix="Colaborador:"
      prepend-icon="mdi-account-box-outline"
      placeholder="todos"
      style="max-width: 510px"
      @change="getPage()"
    ></v-autocomplete>
    <v-spacer></v-spacer>
    <AsyncExportButton
      ref="asyncExportButton"
      :beneficio="beneficio"
      :competencia="intervaloCompetencia"
      class="hidden"
      type="captacao-dispendio-rh"
    />
    <Modal ref="recalcModal">
      <p><b> Selecione o período para finalização:</b></p>
      <v-menu
        v-model="dateMenu"
        :close-on-click="competenciaRecalculo.length == 2"
        :close-on-content-click="false"
        transition="scroll-y-transition"
        offset-y
        right
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="competenciaRecalculo | toMonthPeriod"
            dense
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            @click:prepend.stop="on.click"
            v-on="on"
            hide-details
            class="mb-n1 mt-0 mr-3"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="competenciaRecalculo"
          range
          type="month"
          @click:month="dateMenu = competenciaRecalculo.length != 2"
          no-title
        ></v-date-picker>
      </v-menu>
      <br />
      <p>
        Selecione um ou mais colaboradores. Deixe em branco para selecionar
        todos.
      </p>
      <v-autocomplete
        v-model="colaboradoresSelecionados"
        :disabled="!colaboradores || colaboradores.length === 0"
        :items="colaboradores"
        item-text="nome"
        item-value="id"
        placeholder="Todos colaboradores"
        dense
        multiple
        hide-details
        clearable
        prepend-icon="mdi-account"
        :search-input.sync="searchInputValue"
        @blur="searchInputValue = ''"
      ></v-autocomplete>
      <v-radio-group v-model="recalcularTodas" row>
        <v-radio label="Apenas folhas alteradas" :value="false"></v-radio>
        <v-radio label="Todas as folhas" :value="true"></v-radio>
      </v-radio-group>

      <template #buttons="{ close }">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="pr-5" dark depressed @click="close()">
          <v-icon left>mdi-chevron-left</v-icon>Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          depressed
          class="px-5 ml-3"
          @click="doRecalc(close)"
        >
          Executar
        </v-btn>
      </template>
    </Modal>
    <Pagination
      v-if="showPagination"
      v-model="page"
      :length="totalPages"
      @change="getPage()"
    />
    <DropdownCaptacao />
  </Table>
</template>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.pagination::v-deep .v-input input {
  padding: .125rem 0 .125rem .25rem;
  width: 2rem;
}

.masterDetail::v-deep {
  .v-card__text.d-flex {
    align-items: center;

    div.monthly-filter-field {
      margin-top: 0.5rem !important;
    }

    > div:last-of-type {
      display: flex;
      flex: 1;
    }
  }

  .table-v-tr {
    &:hover {
      background-color: transparent !important;
      cursor: auto;
    }

    &:nth-last-child(6),
    &:nth-last-child(3),
    &:nth-last-child(1) {
      background-color: var(--v-table-header-base);

      &:hover {
        background-color: var(--v-table-header-base) !important;
      }

      * {
        font-weight: bolder !important;
      }
    }
  }
}
</style>
